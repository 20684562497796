<template>
  <div>
    <div v-if="getPdfData().length">
      <div
        class="flix-form-group"
        :key="updateChecked"
        v-if="Object.keys(checked).length"
      >
        <csvDownload
          :className="{ 'flix-html-a': true }"
          :data="getPdfData()"
          :filter="filter"
          v-if="id && id !== 'all'"
          :form="id"
        />
        <downloadPDF
          :className="{ 'flix-html-a': true }"
          :entry="getPdfData()"
          >{{
            $t('message.selectedData', { name: $t('message.pdfExport') })
          }}</downloadPDF
        >
        <messenger :data="getPdfData()" />
        <div class="flix-form-group flix-text-right">
          <deleteUser
            :callback="
              () => {
                getLoadDates()
              }
            "
            :entry="getPdfData()"
          />
        </div>
      </div>
    </div>
    <h3 class="flix-html-h3">
      {{ searchUser(clients).length }}
      {{ $tc('message.result', searchUser(clients).length) }}
    </h3>
    <div class="flixTableContainer flix-form-group" v-if="clients.length">
      <transition name="flixFadeIn">
        <div
          :key="JSON.stringify(clients) + $store.getters.bookingCalendarHash"
        >
          <tableMenu
            :clients="clients"
            :page="page"
            :breakpoint="breakpoint"
            :callback="setPage"
          />
          <searchUser :search="search" :callback="setSearch" />
          <tableList
            :filter="filter"
            :clicked="clicked"
            :id="id"
            :clients="getUsers(clients)"
            :updateChecked="updateChecked"
            :setChecked="setChecked"
            :setReorder="setReorder"
            :getChecked="getChecked"
            :setCheckAll="setCheckAll"
            :setClient="setClient"
          />
          <tableMenu
            :clients="clients"
            :page="page"
            :breakpoint="breakpoint"
            :callback="setPage"
          />
        </div>
      </transition>
    </div>
    <div class="flix-alert flix-alert-info" v-else>
      {{ $tc('message.noEntry', 2) }}
    </div>
  </div>
</template>
<script>
import { crmFilter } from '@/components/crm/filter.js'
export default {
  components: {
    tableMenu() {
      return import('@/components/crm/table/menu')
    },
    tableList() {
      return import('@/components/crm/table/list')
    },
    downloadPDF() {
      return import('@/components/crm/pdf_download')
    },
    csvDownload() {
      return import('@/components/csv')
    },
    messenger() {
      return import('@/components/crm/messenger/')
    },
    searchUser() {
      return import('@/components/crm/table/search')
    },
    deleteUser() {
      return import('@/components/crm/delete')
    }
  },
  props: {
    id: [String, Number],
    callback: Function,
    filter: [String, Date]
  },
  data() {
    return {
      update: 0,
      clients: [],
      clicked: [],
      checked: {},
      updateChecked: new Date().getTime(),
      downloadPDF: false,
      colors: {},
      bookings: [],
      page: 1,
      breakpoint: 20,
      search: ''
    }
  },
  methods: {
    setSearch(keyword) {
      this.search = keyword
    },
    setPage(page) {
      this.updateChecked = new Date().getTime()
      this.page = page
    },
    searchUser(u) {
      if (!this.search.trim()) {
        return u
      }
      var keyword = this.search.toLowerCase().trim()
      var user = []
      Object.keys(u).forEach(function (k) {
        var searchData = JSON.parse(JSON.stringify(u[k]))
        if (typeof searchData.date !== 'undefined') {
          delete searchData.date
        }
        if (typeof searchData.date_beauty !== 'undefined') {
          delete searchData.date_beauty
        }
        if (
          JSON.stringify(searchData).toLowerCase().trim().indexOf(keyword) !==
          -1
        ) {
          user.push(u[k])
        }
      })
      return user
    },
    getUsers(u) {
      u = this.searchUser(u)
      var user = []
      for (
        var i = this.page * this.breakpoint - this.breakpoint;
        i < this.page * this.breakpoint;
        i++
      ) {
        if (typeof u[i] !== 'undefined') {
          user.push(u[i])
        }
      }
      return user
    },
    setClient(ret, index) {
      this.clients[index] = ret
    },
    getColors(callback) {
      var c = this.$store.getters.colors
      Object.values(this.$store.getters.assistents).forEach(
        function (key, index) {
          this.colors[key.ID] = c[index]
        }.bind(this)
      )
      callback()
    },
    getPdfData() {
      var r = []
      var clients = this.searchUser(this.clients)
      var checked = this.checked
      Object.keys(checked).forEach(function (check) {
        Object.values(clients).forEach(function (client) {
          if (client.booking_id === check) {
            if (checked[check]) {
              r.push(client)
            }
          }
        })
      })
      return r
    },
    setCheckAll(state) {
      let filter = false
      if (typeof state === 'string') {
        filter = state
        this.checked = []
      }
      Object.keys(this.clients).forEach((k) => {
        if (filter) {
          if (this.clients[k].status === filter) {
            this.checked[this.clients[k].booking_id] = true
          }
        } else {
          this.checked[this.clients[k].booking_id] = state
        }
      })
      this.updateChecked = new Date().getTime()
    },
    setChecked(index) {
      this.checked[index] = !this.checked[index]
      this.updateChecked = new Date().getTime()
      this.downloadPDF = false
    },
    getChecked(index) {
      if (typeof this.checked[index] === 'undefined' || !this.checked[index]) {
        return false
      }
      return true
    },

    setReorder(direction, filter) {
      var o = {}
      this.clicked = [direction, filter]
      this.clients.forEach(function (v, k) {
        if (filter === 'email') {
          o[v.email + Math.random(0, 1) + '' + k] = v
        }
        if (filter === 'appointments') {
          o[v.begin + Math.random(0, 1) + '' + k] = v
        }
        if (filter === 'status') {
          o[v.status + Math.random(0, 1) + '' + k] = v
        }
        if (filter === 'calendar') {
          o[v.assistent_title + v.form + Math.random(0, 1) + '' + k] = v
        }
      })
      var keys = Object.keys(o)
      keys.sort()

      if (direction === 'up') {
        keys.reverse()
      }

      this.clients = []
      keys.forEach(
        function (k) {
          this.clients.push(o[k])
        }.bind(this)
      )
    },
    getCustomers() {
      Object.keys(this.bookings).forEach(
        function (k) {
          if (this.id && this.id !== 'all' && k !== this.id) {
            return true
          }
          this.bookings[k].forEach(
            function (v) {
              var filter = new crmFilter(this.filter)
              if (filter.check(v) && v.validated) {
                this.clients.push(v)
                this.checked[v.booking_id] = true
              }
            }.bind(this)
          )
        }.bind(this)
      )

      this.callback()
    },
    getLoadDates() {
      this.clients = []
      this.checked = {}
      this.variables = this.$getUserVariables()
      this.bookings = this.$store.getters.bookings
      this.getColors(
        function () {
          this.getCustomers()
        }.bind(this)
      )
    }
  },
  mounted() {
    this.getLoadDates()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
